<template>
  <el-container class="el_container">
    <el-header>
      <div class="logo">
        <img src="../assets/logo.png" alt="" />
        武汉市公义医疗器械有限公司管理系统
      </div>
      
      
      <div class="flex_ac">
        <el-popover
          popper-class="h_popper"
          placement="bottom"
          :width="100"
          trigger="hover"
        >
          <template #reference>
            <div class="user">
              <div>
                  <img style="object-fit: cover;" :src="$http+$store.state.userInfo.photo" v-if="$store.state.userInfo.photo" alt="" />
                  <img src="../assets/avatar.jpg" v-if="!$store.state.userInfo.photo" alt="" />
              </div>
              <div class="content">
                <p class="name">{{$store.state.userInfo.full_name || $store.state.userInfo.username}}</p>
                <p class="phone">{{$store.state.userInfo.mobile || '暂无字段'}}</p>
              </div>
            </div>
          </template>
          <div class="login_out">
            <ul>
              <li @click="try_edit">修改密码</li>
              <li @click="loginOut">退出</li>
            </ul>
          </div>
        </el-popover>
        
        <el-badge :value="$store.state.unread_num" v-if="have_auth('/msg')" class="item" style="margin-left: 20px;">
         <el-button type="warning" @click="$router.push('/Msg')" size="mini" icon="el-icon-s-comment" circle></el-button>
        </el-badge>
      </div>
    </el-header>
    <el-container style="overflow: hidden;">
      <el-aside :width="isCollapse ? '64px' : '200px'" style="position: relative;">
        
        <el-scrollbar hidex style="position:absolute; width: 100%;height: 100%; left: 0; top:0; ">
            <asideMenu :isCollapse="isCollapse" />
        </el-scrollbar>
      </el-aside>
      <el-main style="display: flex;flex-direction: column;">
        <div class="top">
          <i
            id="isCollapse"
            :class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"
            @click="isCollapse = !isCollapse"
          ></i>
          <Breadcrumb />
        </div>
        <router-view
      /></el-main>
    </el-container>
  </el-container>
  
  <el-dialog v-model="dialogVisible" width="500px" append-to-body :show-close="false">
    <template #title>
      <div class="dialog_box">
        <div class="con_title"><span></span> 修改密码</div>
      </div>
    </template>
    <el-form ref="form" :rules="rules" :model="form" label-width="120px">
        <el-form-item label="新密码：" prop="password">
            <el-input v-model="form.password" maxlength="20" placeholder="请输入新密码" style="width:100%" />
        </el-form-item>
        <el-form-item label="确认新密码：" prop="cpassword">
            <el-input v-model="form.cpassword" maxlength="20" placeholder="请确认新密码" style="width:100%" />
        </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" :loading="is_ajax" @click="submit">提交</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { ElMessageBox } from "element-plus";
import { httpGet } from "@/apis/api";
import Breadcrumb from "@/components/bread";
import asideMenu from "@/components/asideMenu";
let times=null;
export default {
  components: {
    Breadcrumb,
    asideMenu,
  },

  data() {
    return {
      isCollapse: false,
      userInfo: {},
      myPath: "",
      dialogVisible:false,
      form: {
          
      },
      is_ajax:false,
      rules: {
        password: [
          { required: true, message: "新密码不能为空",trigger: "blur"},
          { min: 6, message: "密码长度不能小于6位",trigger: "blur"}
        ],
        cpassword: [
          { required: true, message: "请确认密码",trigger: "blur"},
          { min: 6, message: "密码长度不能小于6位",trigger: "blur"}
        ]
      },
    };
  },

  watch: {
    $route(val) {
      if (this.$route.matched[1] && this.$route.matched[1].meta)
        this.myPath = this.$route.matched[1].meta.name;
    },
  },
  mounted() {
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    times=setInterval(()=>{
        this.get_msg()
    },20000)
    this.get_msg()
  },
  created() {
    this.myPath = this.$route.matched[1].meta.name;
    if(times){
        clearInterval(times)
    }
    
  },
  methods: {
    try_edit(){
        this.dialogVisible=true
        this.$httpGet("/backend/sys.Admin/edit", {id:this.$store.state.userInfo.admin_id}).then((res) => {
            if (res.status == 200) {
                this.info=res.data;
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            console.log(err);
        });
    },
    // 表单重置
    reset(pid) {
      this.form = {
        
      };
      if(this.$refs['form']){
        this.$refs['form'].clearValidate();
      }
    },
    submit(){
        this.$refs['form'].validate((valid)  => {
          if (valid) {
            if(this.form.password!=this.form.cpassword){
                this.$message.error('两次密码输入不一致');
                return false
            }
            this.is_ajax=true;
            let _data={...this.info};
            _data.password=this.form.password;
            this.$httpPost("/backend/sys.Admin/update",_data).then((res) => {
                if (res.status == 200) {
                    this.$message.success('修改成功');
                    this.dialogVisible=false;
                } else {
                    this.$message.error(res.message);
                }
                this.is_ajax=false;
            }).catch((err) => {
                console.log(err);
                this.is_ajax=false;
            });
          }
        });
    },
    goUser() {
      this.$router.push("/setting");
      window.sessionStorage.setItem("activeIndex", "7");
    },
    get_msg() {
        this.$httpGet("/backend/Message/getCount",{}).then((res) => {
            if (res.status == 200) {
                this.$store.commit("unread_msg",res.data || 0);
            } else {
            }
        }).catch((err) => {
            console.log(err);
        });
    },
    loginOut() {
      ElMessageBox.confirm("即将退出", "退出", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$store.commit("LOGOUT");
        this.$router.push("/login");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
// .el-menu-vertical-demo:not(.el-menu--collapse) {
//   width: 200px;
//   min-height: 400px;
// }
.login_out {
  li {
    margin-bottom: 10px;
    padding-left: 10px;
    cursor: pointer;
    &:hover {
      color: #409eff;
    }
  }
}
.el-aside {
  // height: 100vh;
}
.el_container {
  height: 100%;
}

.el-main {
  background: #f2f3f5;
  padding: 0;
}
.el-menu {
  border-right: none;
}
#isCollapse {
  font-size: 24px;
  cursor: pointer;
  margin: 0 15px;
  &:hover {
    color: #409eff;
  }
}

.el-header {
  padding: 0;
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #409eff;
  .logo {
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    img {
      height: 40px;
      vertical-align: middle;
    }
  }
  .user {
    display: flex;
    justify-content: space-around;
    cursor: pointer;
    color: #fff;
    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      vertical-align: middle;
    }
    .content {
      margin-left: 10px;
      font-size: 14px;
    }
  }
}
.top {
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
</style>
